:root {
    /* Color styles */
    --background-light: rgba(28, 28, 29, 1);
    --link-color: #ffc107;
    --link-color-hover: #e0a800;
    --text-color: rgba(255, 255, 255, 1);
    --text-color-light: rgba(152, 152, 158, 1);
    --background-dark: rgba(0, 0, 0, 1);
    --colors--red: rgba(235, 85, 69, 1);
    --colors--orange: rgba(241, 163, 60, 1);
    --colors--yellow: rgba(248, 215, 74, 1);
    --colors--green: rgba(103, 206, 105, 1);
    --colors--lightblue: rgba(137, 193, 249, 1);
    --colors--blue: rgba(59, 130, 246, 1);
    --colors--purple: rgba(93, 92, 222, 1);
    --colors--berry: rgba(235, 93, 122, 1);
    --colors--lavender: rgba(201, 131, 238, 1);
    --colors--sand: rgba(195, 167, 123, 1);
    --colors--gray: rgba(116, 125, 134, 1);
    --colors--rose: rgba(226, 183, 176, 1);

    /* Text-size styles */
    /* base size: body (17px) */
    --large--title: 2rem;
    --title-1: 1.65rem;
    --title-2: 1.29rem;
    --title-3: 1.18rem;
    --headline: 1rem;
    --body: 1rem;
    --callout: 0.94rem;
    --subhead: 0.88rem;
    --footnote: 0.76rem;
    --caption1: 0.71rem;
    --caption2: 0.65rem;
}