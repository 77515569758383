@import "bootstrap.min.css";
@import "vars.css";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-dark);
  color: var(--text-color);
  font-size: 17px;
}

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-color-hover);
}

.disable-zoom {
    touch-action: manipulation;
    user-select: none;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* calendar hack */
.addToCal a {
  color: var(--link-color);
  cursor: pointer;
  touch-action: manipulation;
}

.addToCal .btn {
  display: none;
}

.addCalIOS ul,
.addCalAndroid ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.addCalAndroid li a:not(.google-link) {
    display: none;
}

.addCalIOS li a:not(.apple-link) {
    display: none;
}

/* end of calendar hack */

.single-pulse {
    overflow: visible;
    position: relative;
}

.single-pulse::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity 0.3s, transform 0.3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1);
    opacity: 0;
}

.pulse {
    overflow: visible;
    position: relative;
}

.pulse::before {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity 0.3s, transform 0.3s;
    animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    opacity: 0;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

.modal-content {
  background-color: var(--background-light);
}

.modal-header,
.modal-footer {
  border-bottom-color: var(--background-dark);
  border-top-color: var(--background-dark);
}
.modal-backdrop.show {
  opacity: 0.86;
}

.close,
.close:hover {
  color: var(--text-color);
}

.featurelist {
    margin-left: 0;
    padding-left: 20pt;
}

.featurelist li {
    margin-bottom: 7pt;
}
