.container {
    background-color: var(--link-color);
    /* disable zoom on ios */
    touch-action: manipulation;
    border: 1px solid var(--link-color);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: 600;
}

.container:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.container:hover {
    background-color: var(--link-color-hover);
    border-color: var(--link-color-hover);
}

.cardContainer {
    display: block;
    width: 100%;
    padding: 10px 5px;
    border-radius: 12px;
}
