.wrapper {
    height: 32px;
    width: 32px;
    display: block;
    border-radius: 16px;
    position: relative;
}

.icon {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-top: 50%;
    margin-left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
